@import '~vuetify/src/styles/styles.sass';



























































































































































































































































































































































































































//@import '@design';
.stickyBanner {
  background-color: red !important;
  position: sticky;
  top: 0;
}
